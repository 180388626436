import React, { useEffect } from "react";
import { Link } from "@reach/router";
import { navigate} from "gatsby"
import DotaznikLayout from "../../../components/nabidka/dotaznik/DotaznikLayout";


let CoNejrychleji = ({userData, updateData}) => {
  let uprava = userData;

  function odpoved(co) {
    uprava.coNejrychleji = {
      "otazka": "Potřebujete, abychom přijeli co nejrychleji?",
      "odpoved": co
    }

    updateData(uprava);
    navigate("/nabidka/dotaznik/region"); //zmenit
  }


  return (
    <DotaznikLayout phase={1} progress={90} isSupp>
      <h1>Potřebujete, abychom přijeli co nejrychleji?</h1>
      <p>Ano - v případě, že váš blízký zemřel nedávno a například doma. Ne - pokud je k dispozici márnice nebo jiná pohřební služba.</p>
      <button onClick={() => odpoved("Ano")}>Ano</button>
      <button onClick={() => odpoved("Ne")}>Ne</button>
      <Link to="/nabidka/dotaznik/stav-blizkeho" className="zpet">← Zpět</Link>
    </DotaznikLayout>
  );
};


export default CoNejrychleji